/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Kocaeli ili, İzmit ilçesi, Akçakoca Mahallesi, Orhan Caddesi, 19 pafta, 378 ada, 19 parselde yer alan, zemin+2 katlı, kırma çatılı tescilli yapı, iç ve dış cephe olarak, farklı mimari özelliklere sahiptir. Bağdadi çatkı sistemi ve taş ve tuğla malzemeden kurgulanmış hımış yapının, Güney ve Doğu cephesinde, ahşap kaplama görülürken, Kuzey ve Batı Cephesinde sıva yüzeylidir. Plan şemasını ve cephe özelliklerini büyük ölçüde koruyarak günümüze ulaştırmış yapının zemin katında malzemeye dayalı müdahaleler yanı sıra yapının bütününde strüktürel deformasyonlar görülmektedir.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
